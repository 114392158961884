import {
  ADD_TO_WISHLIST,
  REMOVE_FROM_WISHLIST,
  CLEAR_WISHLIST,
} from "../types/types";

const initialState = {
  wishlist: [],
};

const wishlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_WISHLIST:
      // console.log(action.payload)
      return {
        wishlist: action.payload.wishlist,
      };
    case REMOVE_FROM_WISHLIST:
      // console.log(action.payload)
      return {
        wishlist: action.payload.wishlist,
      };
    case CLEAR_WISHLIST:
      return {
        wishlist: action.payload.wishlist,
      };
    default:
      return state;
  }
};

export default wishlistReducer;
