export const LOGOUT = "LOGOUT";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SEND_DATA = "SEND_DATA";
export const EARN_EXPENSE_SEND_DATA = "EARN_EXPENSE_SEND_DATA";
export const SET_CURRENT_MERCHANT = "SET_CURRENT_MERCHANT";
export const MERCHANT_LOGOUT = "MERCHANT_LOGOUT";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const UPDATE_CART_INCREMENT = "UPDATE_CART_INCREMENT";
export const UPDATE_CART_DECREMENT = "UPDATE_CART_DECREMENT";
export const CLEAR_CART = "CLEAR_CART";
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST";
export const CLEAR_WISHLIST = "CLEAR_WISHLIST";
export const LOADING_ALL_ABADS ="LOADING_ALL_ABADS";
export const GET_ALL_ABADS ="GET_ALL_ABADS";
export const GET_ALL_KHAMARS = "GET_ALL_KHAMARS";
export const LOADING_ALL_KHAMARS = "LOADING_ALL_KHAMARS";
export const LOADING_ALL_MOUSHUMS = "LOADING_ALL_MOUSHUMS";
export const GET_ALL_MOUSHUMS = "GET_ALL_MOUSHUMS";
export const SEND_KHAMAR_INDEX = "SEND_KHAMAR_INDEX";
export const SEND_MOUSHUM_INDEX = "SEND_MOUSHUM_INDEX";
export const SEND_MOUSHUM_DATA = "SEND_MOUSHUM_DATA";