// import setAuthToken from "../../utils/setAuthToken";
import setCurrentUser from "./setCurrentUser";
import setCurrentMerchant from "./setCurrentMerchant";

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("farmertoken");
  // setAuthToken(false);

  dispatch(setCurrentUser({}));
};

export const logoutMerchant = () => (dispatch) => {
  localStorage.removeItem("merchanttoken");
  // setAuthToken(false);

  dispatch(setCurrentMerchant({}));
};
