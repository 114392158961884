import axios from "axios";
import { LOADING_ALL_KHAMARS, GET_ALL_KHAMARS } from "../types/types";

const fetchKhamarsPending = () => {
  return {
    type: LOADING_ALL_KHAMARS,
  };
};

const fetchKhamarsSuccess = (data) => {
  return {
    type: GET_ALL_KHAMARS,
    payload: data,
  };
};

export const fetchKhamars = () => async (dispatch) => {
  dispatch(fetchKhamarsPending());
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_NOT_SERVER_URL}/api/khamar/showKhamar`
    );
    if (data.success) {
      dispatch(fetchKhamarsSuccess(data.khamar));
    }
  } catch (error) {
    console.log(error.message);
  }
};
