import Product from "../../components/marketplace/Product";
import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  UPDATE_CART_INCREMENT,
  UPDATE_CART_DECREMENT,
  CLEAR_CART,
} from "../types/types";

const initialState = {
  cart: [],
};

const cartReducer = (
  state = { cart: JSON.parse(localStorage.getItem("cartItems") || "[]") },
  action
) => {
  switch (action.type) {
    case ADD_TO_CART:
      // console.log(action.payload);
      return {
        cart: action.payload.cart,
      };
    case REMOVE_FROM_CART:
      // console.log(action.payload)
      return {
        cart: action.payload.cart,
      };
    // case UPDATE_CART_DECREMENT:
    //   return {
    //     ...state,
    //     cart: state.cart.map((c) =>
    //       c._id === action.payload ? { ...c, quantity: c.quantity + 1 } : c
    //     ),
    //   };
    // case UPDATE_CART_INCREMENT:
    //   return {
    //     ...state,
    //     cart: state.cart.map((c) =>
    //       c._id === action.payload
    //         ? { ...c, quantity: c.quantity !== 1 ? c.quantity - 1 : 1 }
    //         : c
    //     ),
    //   };
    // case CLEAR_CART:
    //   return {
    //     ...state,
    //     cart: state.cart.map((c) =>
    //       c.selected ? { ...c, selected: false, quantity: 1 } : c
    //     ),
    //   };
    default:
      return state;
  }
};

export default cartReducer;
