import {
  SET_CURRENT_USER,
  LOGOUT,
  SET_CURRENT_MERCHANT,
  MERCHANT_LOGOUT,
} from "../types/types";

const isEmpty = require("is-empty");

const initialState = {
  isAuthenticated: !isEmpty(localStorage.getItem("farmertoken")),
  isMerchantAuthenticated: !isEmpty(localStorage.getItem("merchanttoken")),
  user: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      // console.log(action.payload)
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case SET_CURRENT_MERCHANT:
      // console.log(action.payload)
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case LOGOUT:
      localStorage.removeItem("farmertoken");
      return {
        ...state,
        isAuthenticated: false,
        user: {},
      };
    case MERCHANT_LOGOUT:
      localStorage.removeItem("merchanttoken");
      return {
        ...state,
        isAuthenticated: false,
        user: {},
      };
    default:
      return state;
  }
};

export default authReducer;
