import { LOADING_ALL_KHAMARS, GET_ALL_KHAMARS } from "../types/types";

const initialState = {
  khamars: [],
  khamarsPending: false,
};

const khamarReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_ALL_KHAMARS:
      return {
        ...state,
        khamarsPending: true,
      };
    case GET_ALL_KHAMARS:
      return {
        ...state,
        khamars: action.payload,
        khamarsPending: false,
      };
    default:
      return state;
  }
};

export default khamarReducer;
