import React from "react";
import EllipsisText from "react-ellipsis-text";
import { useHistory } from "react-router-dom";
import Img from "react-cool-img";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/actions/cartAction";
import { addToWishlist } from "../../redux/actions/wishlistAction";

const Product = ({
  name,
  quantity,
  price,
  mainimage,
  id,
  sellertype,
  seller,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <div
      className="card m-4"
      style={{
        maxWidth: "180px",
        width: "180px",
        height: "250px",
        maxHeight: "250px",
      }}
    >
      <div
        style={{ cursor: "pointer" }}
        onClick={() => history.push(`/product/${id}`)}
      >
        <Img
          lazy={true}
          className="card-img-top"
          src={mainimage?.link}
          alt={name}
          style={{ height: "100px", width: "100%", objectFit: "contain" }}
        />
      </div>
      <div className="card-text">
        <EllipsisText
          className=" mt-3 d-flex justify-content-center "
          style={{ fontSize: "14px" }}
          text={name}
          length={12}
        />
        {/* <h5 className=" mt-3 d-flex justify-content-center">{name}</h5> */}
        <h6
          className="d-flex justify-content-center"
          style={{ fontSize: "14px" }}
        >
          {seller?.name}
        </h6>
        <h6
          className=" d-flex justify-content-center"
          style={{ fontSize: "14px" }}
        >
          {quantity.toLocaleString("bn-BD")} ইউনিট
        </h6>
        <h6
          className=" d-flex justify-content-center mx-auto"
          style={{ fontSize: "14px" }}
        >
          <b>৳ {price.toLocaleString("bn-BD")}</b>
        </h6>
        {sellertype === "merchant" ? (
          <button
            className="btn btn-primary mx-auto w-100"
            style={{ fontSize: "14px" }}
            onClick={() =>
              dispatch(addToCart({ id, name, price, mainimage, quantity }))
            }
          >
            কার্টে অ্যাড করুন
          </button>
        ) : (
          <button
            className="btn btn-primary mx-auto w-100"
            style={{ fontSize: "14px" }}
            onClick={() =>
              dispatch(
                addToWishlist({ id, name, price, mainimage, quantity, seller })
              )
            }
          >
            উইশলিস্ট অ্যাড করুন
          </button>
        )}
      </div>
    </div>
  );
};
export default Product;
