import { useEffect, useRef } from "react";
import { FaSearch } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
import { FaStethoscope } from "react-icons/fa";
import { FaUserCircle } from "react-icons/fa";
import { Navbar, Form, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import krl from "../assets/images/k.png";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/actions/authAction";
import "../css/Navbar-2.css";
import Img from "react-cool-img";

const NavBar = ({ setNavHeight }) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const heightRef = useRef(null);
  useEffect(() => {
    if (heightRef.current) {
      let navbarheight = heightRef.current.offsetHeight;
      setNavHeight(navbarheight);
    }
  }, [heightRef, setNavHeight]);
  return (
    <div
      ref={heightRef}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
      className="bg-light"
    >
      <Navbar
        className="navbar navbar-light d-flex flex-column"
        expand="lg"
        style={{ width: "100vw", maxWidth: "1400px" }}
      >
        <div className="container-fluid">
          <div className="kernel-logo" style={{ paddingRight: "20px" }}>
            <Link to="/">
              <Img lazy={true} src={krl} alt="logo" />
            </Link>
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className=" mt-3">
            <Nav className="ml-auto">
              <div className="input-group d-flex align-items-center w-xs-100 w-s-100 searchbar">
                <input
                  className="form-control input-group-sm "
                  type="search"
                  placeholder="খোজ করুন..."
                  aria-label="Search"
                />
                <div className="input-group-append">
                  <button className="btn btn-dark" type="submit">
                    <FaSearch />
                  </button>
                </div>
              </div>

              <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
                <li className="nav-item ml-3">
                  <Link className="nav-link" to="/marketplace">
                    <FaShoppingCart
                      style={{ margin: "7.5px" }}
                    ></FaShoppingCart>
                    <b>বেচাকেনা</b>
                  </Link>
                </li>
                <li className="nav-item ml-3">
                  <Link className="nav-link" to="#">
                    <FaShoppingCart
                      style={{ margin: "7.5px" }}
                    ></FaShoppingCart>
                    <b>কৃষিটিউব</b>
                  </Link>
                </li>
                <li className="nav-link ml-3 d-flex align-items-start">
                  <div>
                    <FaShoppingCart
                      style={{ margin: "7.5px" }}
                    ></FaShoppingCart>
                    <b style={{ marginRight: "4px" }}>সেবাসমুহ</b>
                  </div>
                  <NavDropdown
                    id="collapsible-nav-dropdown"
                    style={{ marginTop: "-5px" }}
                  >
                    <div className="row ffg m-0">
                      <div className=" col-lg-6 border-md-right">
                        <NavDropdown.Item
                          className="p-1 my-3"
                          as={Link}
                          to="/kjobs"
                        >
                          <div className="d-flex align-items-center">
                            <FaShoppingCart style={{ marginRight: "5px" }} />
                            <div>কৃষি জবস</div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          className="p-1 my-3"
                          as={Link}
                          to="/ktraining"
                        >
                          <div className="d-flex align-items-center">
                            <FaShoppingCart style={{ marginRight: "5px" }} />
                            <div>কৃষি প্রশিক্ষন</div>
                          </div>
                        </NavDropdown.Item>
                      </div>
                      <div className="col-lg-6 border--mdleft">
                        <NavDropdown.Item
                          className="p-1 my-3"
                          as={Link}
                          to="/kdoctor"
                        >
                          <div className="d-flex align-items-center">
                            <FaShoppingCart style={{ marginRight: "5px" }} />
                            <div>কৃষি ডাক্তার</div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          className="p-1 my-3"
                          as={Link}
                          to="/ksuggesstion"
                        >
                          <div className="d-flex align-items-center">
                            <FaShoppingCart style={{ marginRight: "5px" }} />
                            <div>কৃষি পরামর্শ</div>
                          </div>
                        </NavDropdown.Item>
                      </div>
                    </div>
                  </NavDropdown>
                </li>
                <li className="nav-item ml-3">
                  <Link className="nav-link" to="/khamargorun">
                    <FaStethoscope style={{ margin: "7.5px" }}></FaStethoscope>
                    <b>খামার ব্যাবস্থাপনা</b>
                  </Link>
                </li>
                {isAuthenticated ? (
                  <li className="nav-item ml-3">
                    <Nav.Link onClick={() => dispatch(logoutUser())}>
                      <FaUserCircle style={{ margin: "7.5px" }}></FaUserCircle>
                      <b>লগ আউট</b>
                    </Nav.Link>
                  </li>
                ) : (
                  <li className="nav-item ml-3">
                    <Link className="nav-link" to="/login">
                      <FaUserCircle style={{ margin: "7.5px" }}></FaUserCircle>
                      <b>লগ ইন</b>
                    </Link>
                  </li>
                )}
                {isAuthenticated && (
                  <li className="nav-item ml-3">
                    <Link className="nav-link" to="/profile">
                      <FaUserCircle style={{ margin: "7.5px" }}></FaUserCircle>
                      <b>প্রোফাইল</b>
                    </Link>
                  </li>
                )}
              </ul>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </div>
  );
};

export default NavBar;
