import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  UPDATE_CART_DECREMENT,
  UPDATE_CART_INCREMENT,
  CLEAR_CART,
} from "../types/types";
// Set logged in user
export const addToCart = (data) => (dispatch, getState) => {
  const cart = getState().cart.cart.slice();
  let alreadyExists = false;
  cart.forEach((c) => {
    if (c.id === data.id) {
      alreadyExists = true;
      c.count++;
    }
  });
  if (!alreadyExists) {
    cart.push({ ...data, count: 1 });
  }
  dispatch({
    type: ADD_TO_CART,
    payload: { cart },
  });
  localStorage.setItem("cartItems", JSON.stringify(cart));
};

export const removeFromCart = (data) => (dispatch, getState) => {
  const cart = getState()
    .cart.cart.slice()
    .filter((c) => c.id !== data.id);

  dispatch({
    type: REMOVE_FROM_CART,
    payload: { cart },
  });
  localStorage.setItem("cartItems", JSON.stringify(cart));
};

// export const updateCartIncrement = (data) => {
//   return {
//     type: UPDATE_CART_INCREMENT,
//     payload: data,
//   };
// };

// export const updateCartDecrement = (data) => {
//   return {
//     type: UPDATE_CART_DECREMENT,
//     payload: data,
//   };
// };

// export const clearCart = (data) => {
//   return {
//     type: CLEAR_CART,
//     payload: data,
//   };
// };
