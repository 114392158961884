import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import authReducer from "./authReducer";
import kbReducer from "./kbReducer";
import kbEarnExpenseReducer from "./kbEarnExpenseReducer";
import thunk from "redux-thunk";
import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import abadReducer from "./abadReducer";
import khamarReducer from "./khamarReducer";
import moushumReducer from "./moushumReducer";
import kwmReducer from "./kwmReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  kb: kbReducer,
  kbEarnExpense: kbEarnExpenseReducer,
  cart: cartReducer,
  wishlist: wishlistReducer,
  abad: abadReducer,
  khamar: khamarReducer,
  moushum: moushumReducer,
  kwm:kwmReducer
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = [thunk];

const store = createStore(
  rootReducer,
  {},
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
