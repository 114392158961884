import { useEffect, useRef } from "react";
import "../css/Footer.css";
import "bootstrap/dist/css/bootstrap.min.css";
import kl from "../assets/images/kernel-logo-white.png";
import krl from "../assets/images/krishibee 1.png";
import gpb from "../assets/images/googleplay-btn.png";
import { FaFacebookSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Img from "react-cool-img";

const Footer = ({ setFootHeight }) => {
  const heightRef = useRef(null);
  useEffect(() => {
    if (heightRef.current) {
      let footerheight = heightRef.current.offsetHeight;
      setFootHeight(footerheight);
    }
  }, [heightRef, setFootHeight]);
  return (
    <div className="page-footer footer mb-auto" id="footerback" ref={heightRef}>
      <div className="row footer-body" id="footerback">
        <div className="container text-center text-white text-md-left col-md-3 mt-5 app-info">
          <div className="footerlogo">
            <Img lazy={true} src={krl} alt="" style={{ height: "60px" }} />
          </div>
          <p style={{ height: "100px", marginTop: "15px" }}>
            Flat 5B, House 1/B, Rd-08,
            <br /> Gulshan-1, Dhaka-1212
            <br /> Phone: +8802989377
          </p>
          <p>
            <b>আমাদের মোবাইল অ্যাপ</b>
          </p>
          <div className="mb-4 gp-logo ">
            <Img
              lazy={true}
              src={gpb}
              alt=""
              style={{ height: "70px", marginRight: "5px" }}
            />
          </div>
        </div>
        <div className="container text-center text-white power col-md-2 mt-auto mb-3">
          <p className="mb-0">
            <b>Powered by</b>
          </p>
          <div
            className="kernel-logo"
            style={{ paddingRight: "20px", paddingTop: "20px" }}
          >
            <Img lazy={true} src={kl} alt="" style={{ height: "120px" }} />
          </div>
        </div>
        <div className="container text-center text-white footer-links col-md-5 mx-auto mt-2">
          <div className="row mt-2 f-links">
            <div className="col-md-6 mx-auto menu">
              <p className="h4 heading my-4">Menu</p>
              <ul className="list-unstyled">
                <li>
                  <Link className="text-white" to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="text-white" to="/aboutus">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link className="text-white" to="/contactus">
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link className="text-white" to="/merchant/login">
                    Merchant
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-6 find-us">
              <p className="h4 heading my-4">Find Us on</p>
              <div className="social">
                <ul className="list-unstyled">
                  <li>
                    <FaLinkedin
                      style={{
                        width: "30px",
                        height: "25px",
                        marginRight: "10px",
                      }}
                    />{" "}
                    <p>Linked In</p>
                  </li>
                  <li>
                    <FaInstagram
                      style={{
                        width: "30px",
                        height: "25px",
                        marginRight: "10px",
                      }}
                    />{" "}
                    <p>Instagram</p>
                  </li>
                  <li>
                    <FaFacebookSquare
                      style={{
                        width: "30px",
                        height: "25px",
                        marginRight: "10px",
                      }}
                    />{" "}
                    <p>Facebook</p>
                  </li>
                  <li>
                    <FaTwitterSquare
                      style={{
                        width: "30px",
                        height: "25px",
                        marginRight: "10px",
                      }}
                    />{" "}
                    <p>Twitter</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="copyright-info">
            <b>© 2020 - krishibee.com | All Rights Reserved</b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
