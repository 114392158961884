import {
  ADD_TO_WISHLIST,
  REMOVE_FROM_WISHLIST,
  CLEAR_WISHLIST,
} from "../types/types";
// Set logged in user
export const addToWishlist = (data) => (dispatch, getState) => {
  const wishlist = getState().wishlist.wishlist.slice();
  let alreadyExists = false;
  wishlist.forEach((w) => {
    if (w.id === data.id) {
      alreadyExists = true;
    }
  });
  if (!alreadyExists) {
    wishlist.push({ ...data });
  }
  dispatch({
    type: ADD_TO_WISHLIST,
    payload: { wishlist },
  });
  localStorage.setItem("wishlistItems", JSON.stringify(wishlist));
};

export const removeFromWishlist = (data) => (dispatch, getState) => {
  const wishlist = getState()
    .wishlist.wishlist.slice()
    .filter((w) => w.id !== data.id);

  dispatch({
    type: REMOVE_FROM_WISHLIST,
    payload: { wishlist },
  });
  localStorage.setItem("wishlistItems", JSON.stringify(wishlist));
};

export const clearWishlist = () => (dispatch) => {
  dispatch({
    type: CLEAR_WISHLIST,
    payload: [],
  });
};
