import { useState, useEffect, lazy, Suspense } from "react";
import { Link, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import NavBar from "../components/Navbar";
// import Login from "../Screens/Login";
// import Signup from "../Screens/Signup";
// import Khamar from "../Screens/khamarbyabosthapona/Khamar";
// import KhamarGorun from "../Screens/khamarbyabosthapona/KhamarGorun";
// import Moushum from "../Screens/khamarbyabosthapona/Moushum";
// import Chash from "../Screens/khamarbyabosthapona/Chash";
// import Earn_Expense from "../Screens/khamarbyabosthapona/Earn_Expense";
// import Archive from "../Screens/khamarbyabosthapona/Archive";
// import Report from "../Screens/khamarbyabosthapona/Report";
// import Profile from "../Screens/profile";
import Footer from "../components/Footer";
// import Editprofile from "../Screens/Editprofile";
// import Marketplace from "../Screens/Marketplace";
// import Blog from "../Screens/Blog";
import FarmerRoute from "./FarmerRoute";
// import Admin from "../Screens/admin/Admin";
// import Product_page from "../Screens/Product_page";
// import AddProduct from "../components/marketplace/AddProduct";
// import Weather from "../Screens/Weather";
// import ProductDetails from "../Screens/ProductDetails";
// import MerchantLogin from "../Screens/merchant/MerchantLogin";
// import Kjobs from "../Screens/Kjobs";
const Home = lazy(() => import("../Screens/Home"));
const Login = lazy(() => import("../Screens/Login"));
const Signup = lazy(() => import("../Screens/Signup"));
const KhamarGorun = lazy(() =>
  import("../Screens/khamarbyabosthapona/KhamarGorun")
);
const Earn_Expense = lazy(() =>
  import("../Screens/khamarbyabosthapona/Earn_Expense")
);
const Earn_Expense_Details = lazy(() =>
  import("../Screens/khamarbyabosthapona/Earn_Expense_Details")
);
const Earn_Expense_Close = lazy(() =>
  import("../Screens/khamarbyabosthapona/Earn_Expense_Close")
);
const Archive = lazy(() => import("../Screens/khamarbyabosthapona/Archive"));
const Report = lazy(() => import("../Screens/khamarbyabosthapona/Report"));
const Profile = lazy(() => import("../Screens/profile"));
const Editprofile = lazy(() => import("../Screens/Editprofile"));
const Marketplace = lazy(() => import("../Screens/Marketplace"));
const Blog = lazy(() => import("../Screens/Blog"));
const Admin = lazy(() => import("../Screens/admin/Admin"));
const Product_page = lazy(() => import("../Screens/Product_page"));
const AddProduct = lazy(() => import("../components/marketplace/AddProduct"));
const Weather = lazy(() => import("../Screens/Weather"));
const ProductDetails = lazy(() => import("../Screens/ProductDetails"));
const MerchantLogin = lazy(() => import("../Screens/merchant/MerchantLogin"));
const Kjobs = lazy(() => import("../Screens/Kjobs"));
const Kdoctor = lazy(() => import("../Screens/Kdoctor"));

function KrishiRoute() {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [navHeight, setNavHeight] = useState(0);
  const [footHeight, setFootHeight] = useState(0);
  const [bodyHeight, setBodyHeight] = useState(0);
  useEffect(() => {
    setBodyHeight(navHeight + footHeight);
  }, [footHeight, navHeight]);
  return (
    <>
      <NavBar setNavHeight={(navht) => setNavHeight(navht)} />
      <div style={{ minHeight: `calc(100vh - ${bodyHeight}px)` }}>
        <Suspense
          fallback={
            <div
              className="w-100 d-flex flex-row justify-content-center align-items-center"
              style={{ height: `${bodyHeight}px` }}
            >
              <div className="spinner-grow text-success mx-2" role="status">
                <span className="sr-only"></span>
              </div>
              <div className="spinner-grow text-success mx-2" role="status">
                <span className="sr-only"></span>
              </div>
              <div className="spinner-grow text-success mx-2" role="status">
                <span className="sr-only"></span>
              </div>
              <div className="spinner-grow text-success mx-2" role="status">
                <span className="sr-only"></span>
              </div>
              <div className="spinner-grow text-success mx-2" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          }
        >
          <Route path="/" exact={true} component={Home} />
          {!isAuthenticated && (
            <>
              <Route path="/login" exact={true} component={Login} />
              <Route path="/signup" exact={true} component={Signup} />
            </>
          )}
          <Route path="/khamargorun" exact={true} component={KhamarGorun} />
          <Route path="/aya_byaya" exact={true} component={Earn_Expense} />
          <Route path="/aya_byaya_bondho" exact={true} component={Earn_Expense_Close} />
          <Route
            path="/aya_byaya/:id"
            exact={true}
            component={Earn_Expense_Details}
          />
          <Route path="/archive" exact={true} component={Archive} />
          <Route path="/report" exact={true} component={Report} />
          <Route path="/weather" exact={true} component={Weather} />
          <Route path="/product/:pid" exact={true} component={ProductDetails} />
          <FarmerRoute path="/profile" exact={true} component={Profile} />
          <FarmerRoute
            path="/profile/editprofile"
            exact={true}
            component={Editprofile}
          />
          <Route path="/addproduct" exact={true} component={AddProduct} />
          <Route path="/marketplace" exact={true} component={Marketplace} />
          <Route path="/kblog" exact={true} component={Blog} />
          <Route path="/kjobs" exact={true} component={Kjobs} />
          <Route path="/kdoctor" exact={true} component={Kdoctor} />
          <Route path="/admin" exact={true} component={Admin} />
          <Route
            path="/marketplace/product"
            exact={true}
            component={Product_page}
          />

          <Route
            path="/merchant/login"
            exact={true}
            component={MerchantLogin}
          />
          {/* <Route path="*" component={NoMatch} /> */}
        </Suspense>
      </div>
      <Footer setFootHeight={(footht) => setFootHeight(footht)} />
    </>
  );
}
export default KrishiRoute;

// const NoMatch = () => {
//   let location = useLocation();

//   return (
//     <div className="container d-flex justify-content-center align-items-center h-100">
//       <div className="m-5">
//         <h1>ত্রুটি 404! পৃষ্ঠা খুঁজে পাওয়া যায়নি</h1>
//         <h3>
//           <code>{location.pathname}</code> পেজটি সঠিক নয়
//         </h3>
//         <h4>
//           <Link to="/">হোম</Link> পেজ এ যান
//         </h4>
//       </div>
//     </div>
//   );
// };
