import { SEND_DATA, SEND_KHAMAR_INDEX } from "../types/types";

const initialState = {
  menudata: {},
  khamarIndex: 0,
};

const kbReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_DATA:
      return {
        ...state,
        menudata: action.payload,
      };
    case SEND_KHAMAR_INDEX:
      return {
        ...state,
        khamarIndex: action.payload,
      };
    default:
      return state;
  }
};

export default kbReducer;
