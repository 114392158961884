import { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./App.css";
import { fetchKhamars } from "./redux/actions/khamarAction";
import { fetchMoushums } from "./redux/actions/moushumAction";
import KrishiRoute from "./routes/Route";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchKhamars());
    dispatch(fetchMoushums());
  }, []);
  return (
    <>
      <KrishiRoute />
    </>
  );
}
export default App;
