import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./redux/reducers/rootReducer";
import "./index.css";
import jwt_decode from "jwt-decode";
import setCurrentUser from "./redux/actions/setCurrentUser";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { logoutUser } from "./redux/actions/authAction";

if (localStorage.farmertoken) {
  const token = localStorage.farmertoken;
  // setAuthToken(token);
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded.data));
  // Check for expired token
  const currentTime = Date.now() / 1000;
  // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to home
    window.location.href = "./";
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
