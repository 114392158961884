import axios from "axios";
import { LOADING_ALL_MOUSHUMS, GET_ALL_MOUSHUMS } from "../types/types";

const fetchMoushumsPending = () => {
  return {
    type: LOADING_ALL_MOUSHUMS,
  };
};

const fetchMoushumsSuccess = (data) => {
  return {
    type: GET_ALL_MOUSHUMS,
    payload: data,
  };
};

export const fetchMoushums = () => async (dispatch) => {
  dispatch(fetchMoushumsPending());
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_NOT_SERVER_URL}/api/khamar/showMoushums`
    );
    if (data.success) {
      dispatch(fetchMoushumsSuccess(data.moushums));
    }
  } catch (error) {
    console.log(error.message);
  }
};
