import { LOADING_ALL_ABADS, GET_ALL_ABADS } from "../types/types";

const initialState = {
    abads: [],
    abadsPending: false,
}

const abadReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING_ALL_ABADS:
          return {
            ...state,
            abadsPending: true,
          };
        case GET_ALL_ABADS:
          return {
            ...state,
            abads: action.payload,
            abadsPending: false,
          };
        default:
          return state;
      }
    };

export default abadReducer;